.product-image-in-list {
  width: 100px;
}

.list-item-title {
  width: 50%;
}

.list-item-line {
  display: inline-flex;
}

#total-price-line {
  display: flex;
}

@media screen and (max-width: 820px) {
  #title > a > img {
    width: 200px;
  }
    
  .product-image-in-list {
    width: 50px;
  }


}

@media screen and (max-width: 630px) {
  .list-item-property {
    display: grid;
  }
}

@media screen and (max-width: 500px) {
  #title > a > img {
    width: 150px;
  }

  #basket-icon {
    width: 50px !important;
    height: 50px !important;
    background-size: cover;
  }

  #in-basket {
    left: 30px !important;
    bottom: 37px !important;
    font-size: 20px !important;
  }

  li {
    margin-bottom: 10px;
  }

  #total-price-line {
    display: block !important;
  }
}
